import logo from './logo.svg';
import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import SsoIdp from './pages/SsoIdp'
import AccessManager from './pages/AccessManager';
import AMFA from './pages/AMFA';
import CloudDirectory from './pages/CloudDirectory';
import PasswordManager from './pages/PasswordManager';
import UserLifeCycle from './pages/UserLifeCycle';
import HigherEducation from './pages/HigherEducation';
import ITesBPO from './pages/ITesBPO';
import Manufacture from './pages/Manufacture';
import Retail from './pages/Retail';
import Finserv from './pages/Finserv';
import HealthCare from './pages/HealthCare';
import Technology from './pages/Technology';
import NGO from './pages/Ngo';
import PublicSector from './pages/PublicSector';
import Tourism from './pages/Tourism';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ContactUS from './pages/ContactUS';

import ScrollToTop from "./components/header/ScrollToTop";

function App() {

  return (
    <Router>
      <ScrollToTop />
      <Header></Header>
      <Routes>
        <Route path="/" element={<Home></Home>} />
        <Route path="/home" element={<Home />} />
        <Route path="/ssoidp" element={<SsoIdp />} />
        <Route path="/accessmanager" element={<AccessManager />} />
        <Route path="/amfa" element={<AMFA />} />
        <Route path="/clouddirectory" element={<CloudDirectory />} />
        <Route path="/passwordmanager" element={<PasswordManager />} />
        <Route path="/userlifecycle" element={<UserLifeCycle />} />
        <Route path="/higheredu" element={<HigherEducation />} />
        <Route path="/itesbpo" element={<ITesBPO />} />
        <Route path="/manufacture" element={<Manufacture />} />
        <Route path="/retail" element={<Retail />} />
        <Route path="/finserv" element={<Finserv />} />
        <Route path="/healthcare" element={<HealthCare />} />
        <Route path="/technology" element={<Technology />} />
        <Route path="/ngo" element={<NGO />} />
        <Route path="/publicsector" element={<PublicSector />} />
        <Route path="/tourism" element={<Tourism />} />
        <Route path="/contact" element={<ContactUS />} />
        <Route path="/tryit" element={<ContactUS />} />
      </Routes>
      {/* ttt */}
      <Footer></Footer>
    </Router>

  );
}

export default App;
