import React from "react";
import { Link } from 'react-router-dom';

const ContactUS = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center sm:pt-28 md:pt-2 ">
      <div className="container mx-auto flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden">
        {/* Left Section - Get in Touch Form */}
        <div className="w-full md:w-1/2 p-8">
          <h2 className="text-3xl font-bold mb-4">Get in touch</h2>
          <form>
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700">
                Name
              </label>
              <input
                id="name"
                type="text"
                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
                placeholder="Name"
              />
            </div>
            <p className="mb-4">I would like to know more about your:</p>

            <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <label className="flex items-center space-x-2">
                <input type="checkbox" className="form-checkbox" />
                <span className="flex items-center space-x-2">
                  <span>🔗</span> <span>SSO/ IDP</span>
                </span>
              </label>
              <label className="flex items-center space-x-2">
                <input type="checkbox" className="form-checkbox" />
                <span className="flex items-center space-x-2">
                  <span>Password Manager</span>
                </span>
              </label>
              <label className="flex items-center space-x-2">
                <input type="checkbox" className="form-checkbox" />
                <span className="flex items-center space-x-2">
                  <span>AMFA</span>
                </span>
              </label>
              <label className="flex items-center space-x-2">
                <input type="checkbox" className="form-checkbox" />
                <span className="flex items-center space-x-2">
                  <span>Cloud Directory</span>
                </span>
              </label>
              <label className="flex items-center space-x-2">
                <input type="checkbox" className="form-checkbox" />
                <span className="flex items-center space-x-2">
                  <span>Access Manager</span>
                </span>
              </label>
            </div>

            <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 mb-4">
              <input
                type="email"
                placeholder="Work Email"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
              />
              <input
                type="tel"
                placeholder="Phone"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
              />
            </div>
            <button
              type="submit"
              className="px-6 py-2 bg-purple-600 text-white font-medium rounded-lg hover:bg-purple-700 transition-colors"
            >
              Send
            </button>
          </form>
        </div>

        {/* Right Section - Contact Information */}
        <div className="w-full md:w-1/2 p-8 bg-purple-50">
          <h2 className="text-3xl font-bold mb-4">Contact us</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-xl font-bold">INDIA</h3>
              <p className="text-gray-700">
                No 22, East Jones Road, Saidapet, Chennai - 15
                Tamil Nadu, India.
              </p>
              <p className="text-gray-700">
                📞 +91 9389403940
              </p>
              <p className="text-gray-700">✉️ info@blitz-work.com</p>
            </div>
            <div>
              <h3 className="text-xl font-bold">Germany</h3>
              <p className="text-gray-700">
                N 7, 9, Baden-Württemberg, 68161, Germany
              </p>
              <p className="text-gray-700">
                📞 +49 9894849598
              </p>
              <p className="text-gray-700">✉️ info@blitz-work.com</p>
            </div>
            <div>
              <h3 className="text-xl font-bold">United Kingdom</h3>
              <p className="text-gray-700">📞 +44 9799389499</p>
              <p className="text-gray-700">✉️ info@blitz-work.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default ContactUS;